<template>
  <div class="documentation">
    <h1>This is the documentation page</h1>

    <p>Each time you add a device, you'll be able to download a <i class="code">secrets.h</i> file containing:</p>
    <ul>
      <li>the THINGNAME, your device's id</li>
      <li>an ENDPOINT</li>
      <li>3 Certificates (CA, certificate, private key)</li>
    </ul>
    <p><a href="link-to-github" target="_blank">Click here to see an example of how you should use this secret.h file</a></p>
    <p>Once your device is connected, it should show as "Online" in the webserver. This is the first step.</p>
    <p>You can publish a json body to the special topic 'THINGNAME + "/publish_status_to_server"' and that data can be shown in the the webserver. We currently support json with plain key-value format. Ie:</p>
    <p class="json-code">
      {
        "temperature": "60°",
        "humidity": "50%"
      }
    </p>
    <p>If you have more devices, you can subscribe and publish to your own topics to communication between them. Your topics have to start with the value of THINGNAME as prefix.</p>
  </div>
</template>

<style scoped>
.documentation {
  text-align: left;
  margin: 0;
  padding: 16px;
}
</style>
