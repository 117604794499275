<template>
  <div class="client">
    <span class="name" v-if="!editMode">{{ client.name }}</span>
    <input v-else type="text" v-model="newName"/>

    <span v-if="!client.connected" class="connection-status">OFFLINE</span>
    <span v-if="client.connected" class="connection-status connected">ONLINE</span>

    <div class="controls" :class="{ 'controls-disabled': !client.connected }">
      <div v-if="client.status?.estado === 'off'" class="turn-on-controls">
        <!-- Quick timer buttons when device is off -->
        <div class="timer-presets">
          <button 
            v-for="preset in timePresets" 
            :key="preset.value"
            @click="publishOnWithTimer(preset.value)"
            class="preset-button turn-on"
            :disabled="true||!client.connected"
          >
            Turn on for {{ preset.label }}
          </button>
          <button 
            @click="publishOnWithTimer(0)"
            class="preset-button turn-on"
            :disabled="!client.connected"
          >
            Turn on
          </button>
        </div>
      </div>

      <!-- Simple off button when device is on -->
      <div v-else class="turn-off-controls">
        <button 
          @click="publishOff()"
          class="control-button turn-off"
          :disabled="!client.connected"
        >
          Turn Off
        </button>
      </div>
    </div>

    <div v-if="client.status" class="status">
      <span class="item" v-for="(value, name) in client.status" :key="name">
        {{ name }}: {{ value }}
      </span>
    </div>
  </div>
</template>

<style scoped>
.name {
  height: 2em;
  line-height: 2em;
}

.connection-status {
  color: red;
  height: 2em;
  line-height: 2em;
  font-size: 0.8em;
}

.connection-status.connected {
  color: #006400;
}

.status {
  width: 100%;
  font-size: 80%;
}

.status .item {
  padding-right: 8px;
}

.controls {
  margin: 10px 0;
  width: 100%;
}

.controls-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.timer-presets {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  max-width: 400px;
}

.preset-button {
  padding: 12px;
  border-radius: 8px;
  font-size: 1rem;
  touch-action: manipulation;
  cursor: pointer;
  width: 100%;
}

.preset-button.turn-on {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.preset-button.turn-on:active {
  background-color: #3d8b40;
}

.turn-off {
  background-color: #dc3545;
  color: white;
  border: none;
}

.control-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  touch-action: manipulation;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
}

.preset-button:disabled, 
.control-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Make buttons easier to tap on mobile */
@media (max-width: 768px) {
  .preset-button, .control-button {
    min-height: 48px;
  }
}
</style>

<script>
export default {
  props: ['client'],
  data() {
    return {
      editMode: false,
      newName: this.client.name,
      timePresets: [
        { label: '30m', value: 30 },
        { label: '1h', value: 60 },
        { label: '2h', value: 120 }
      ]
    }
  },
  methods: {
    toggleEditMode() {
      this.newName = this.client.name;
      this.editMode = !this.editMode;
    },
    save() {
      this.editMode = !this.editMode;
      if (this.newName !== this.client.name) {
        this.$emit('clientNameEdited', this.newName);
      }
    },
    publishOnWithTimer(minutes) {
      let payload = {
        timerMinutes: minutes,
        payload: "setStatus:on"
      };

      this.$emit('publishRequested', payload);
    },
    publishOff() {
      let payload = {
        timerMinutes: 0,
        payload: "setStatus:off"
      };
      this.$emit('publishRequested', payload);
    }
  }
};
</script>
