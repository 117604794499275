function WebSocketManager() {
  let store = null, WS = null, timeout = 0, reconnect = true;

  this.use = function(_store) {
    store = _store;
  }

  this.connect = function() {
    const self = this;

    reconnect = true;

    WS = new WebSocket(process.env.VUE_APP_ROOT_WS + '?t=' + localStorage.getItem('csrf_token'));
    WS.onmessage = function(event) {
      for (let c of store.state.clients) {
        let data = JSON.parse(event.data);
        if (c.id == data[0]) {
          c[data[1]] = data[2];
          break;
        }
      }
    }

    WS.onopen = function(event) {
      timeout = 250;
      console.log("Successfully connected to the websocket server...")
      console.log(event);
    }

    WS.onclose = function(event) {
      console.log("Disconnected from the websocket server..." + event.code + " " + event.reason + " " + event.wasClean);
      console.log(event);

      if (!reconnect) {
        return;
      }

      setTimeout(self.connect, Math.min(10000, timeout+=timeout));
    }

    WS.onerror = function(event) {
      console.log("Error on websocket ...")
      console.log(event);
    }
  }

  this.disconnect = function() {
    if (!WS) {
      return;
    }

    reconnect = false;
    WS.close();
    WS = null;
  }
}

export default new WebSocketManager();
