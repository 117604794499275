<template>
  <h1>Good bye dear user!</h1>
  <p>See you soon ...</p>
</template>

<script>
import { USER_LOGOUT } from '../store/mutation-types'
export default {
  mounted () {
    this.$store.dispatch(USER_LOGOUT);
  }
};
</script>
