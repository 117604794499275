<template>
  <div class="login-form">
    <form @submit.prevent="onSubmit">
      <div class="row">
        <label>Username</label>
        <input required v-model="username" type="text" />
      </div>
      <div class="row">
        <label>Password</label>
        <input required v-model="password" type="password" />
      </div>
      <div class="row button">
        <button>Login</button>
      </div>
    </form>
  </div>
  <div class="login-error">{{ lastLoginError }}</div>
</template>

<style scoped>
.login-form {
  padding: 32px 0;
}

.row {
  width: 192px;
  margin: 0 auto 16px;
  padding: 0;
}

.login-form label {
  display: inline-block;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 8px 0 8px 4px;
  margin: 0;
  border: 8px;
  box-sizing: border-box;
  background-color: #EBF1F4;
}

.login-error {
  color: red;
}

.row.button {
  text-align: right;
}

.row.button button {
  display: inline-block;
  padding: 2px 8px;
}
</style>

<script>
import { USER_LOGIN } from '../store/mutation-types'

export default {
  computed: {
    lastLoginError () {
      return this.$store.state.lastLoginError
    }
  },
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    onSubmit () {
      const { username, password } = this;
      this.$store.dispatch(USER_LOGIN, { username, password});
    }
  }
};
</script>
