import { createRouter, createWebHashHistory } from 'vue-router'
import ClientList from './components/ClientList.vue'
// import About from './components/About.vue'
import Docs from './components/Docs.vue'
import Login from './components/Login.vue'
import Logout from './components/Logout.vue'

const routes = [
  { path: '/', component: ClientList },
  // { path: '/about', component: About },
  { path: '/docs', component: Docs },
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/docs'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('csrf_token');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})

export {
  router
}
